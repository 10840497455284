<template lang="pug">
include ../../../../configs/template
div.row
  div.col-sm-12.col-md-3.d-flex.align-end
    +field-validation('body.additional_info.other_number', 'number', '["required", "numberAndSymbol"]')(:readonly="isCheckDocument")
  div(v-if="isCheckDocument").col-sm-12.col-md-3.d-flex.align-end
    +field-validation('body.additional_info.other_branch_office', 'affiliate', '["required"]')
  div.col-sm-12.col-md-3.d-flex.align-end
    +date-picker-validation('body.additional_info.date_issued', 'dateIssue', 'dateObjectIssued', '["required", "minValue", "maxValue"]', 'isCheckDocument', '!isCheckDocument')(:readonly="isCheckDocument" :max="new Date().toISOString()")
  div(v-if="isCheckDocument").col-sm-12.col-md-3.d-flex.align-end
    +field-validation('body.additional_info.blank_strict_report', 'strictBlank', '["required", "numeric"]')
  h5(v-if="isCheckDocument").text-left.col-12 {{$t('agent')}} - {{$t('fullNameEn')}}:
  div(v-if="isCheckDocument").col-sm-12.col-md-6
    +field-validation('last_name_eng', 'lastNameEN', '["required", "alphaEN"]')
  div(v-if="isCheckDocument").col-sm-12.col-md-6
    +field-validation('first_name_eng', 'firstNameEN', '["required", "alphaEN"]')

</template>
<script>
import { verificationISC } from '@/mixins/validationRules'

const initBody = () => (
  {
    additional_info: {
      other_number: null,
      other_branch_office: null,
      blank_strict_report: null,
      new_record: false,
      date_issued: null
    }
  }
)

export default {
  props: { isCheckDocument: { type: Boolean, default: false } },
  data () {
    return {
      last_name_eng: null,
      first_name_eng: null,
      body: initBody(),
      isLoading: false
    }
  },
  validations () { return verificationISC.sailorRecordBook(this) },
  computed: {
    dateObjectIssued () {
      return this.body.additional_info.date_issued ? new Date(this.body.additional_info.date_issued) : null
    }
  }

}
</script>
